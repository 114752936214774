.csv-import-container {
    /* max-width: 600px; */
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: Arial, sans-serif;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input[type="number"],
.form-group input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.column-selector {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.column-selector-header {
    font-weight: bold;
    margin-right: 10px;
}

.column-label {
    /* display: block; */
    border: 1px solid #ccc;
    width: 25%;
}

.clear-button {
    background-color: #f44336;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-left: auto;
}

.clear-button:hover {
    background-color: #d32f2f;
}

.csv-data-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
}

.csv-data-header {
    display: flex;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    padding-bottom: 10px;
}

.csv-data-header-item {
    flex: 1;
    padding: 5px 10px;
}

.csv-data-body {
    margin-top: 10px;
}

.csv-data-row {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}

.csv-data-cell {
    flex: 1;
    padding: 5px 10px;
}
.searchInp {
    margin-top: 50px;
    margin-left: 200px;
    padding: 10px;
    border: 2px solid #66cccc;
    border-radius: 5px;
}

.customerlists {
    width: 1100px;
    border-radius: 5px;
    border-left: 1px solid rgb(152, 148, 148);
    border-right: 1px solid rgb(152, 148, 148);
    margin-left: 200px;
    height: 300px;
    overflow: scroll;
}

.customerlist {
    border-bottom: 1px solid rgb(214, 211, 211);
    padding: 5px 0px 5px 7px;
}

.customerlist li {
    list-style: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;


}

.detailsCst {
    padding: 15px;
    margin-left: 185px;
    margin-top: 10px;
}

.detailsCst p {
    margin-top: -10px;
    font-weight: 400;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(149, 149, 149);
}

.detailsCst button {
    float: right;

}

.DltBtn {
    width: 80px;
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    border-radius: 5px;
    float: right;
    margin-top: -50px;
    margin-right: 720px;
}

.OrdrBtn {
    width: 100px;
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    border-radius: 5px;
    float: right;
    margin-top: -50px;
    margin-right: 570px;
    padding-left: 10px;
}

.CompBtn {
    width: 140px;
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
    border-radius: 5px;
    float: right;
    margin-top: -50px;
    margin-right: 380px;
    padding-left: 10px;
}

.BillBtn {
    width: 140px;
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
    border-radius: 5px;
    float: right;
    margin-top: -50px;
    margin-right: 220px;
    padding-left: 10px;
}

.product_header {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    margin-top: -10px;
    height: 100px;
    box-shadow: 0px 0px 15px 0px #ccc;
}

.R {
    margin-top: 10px;
}



.POD {
    margin-top: 50px;
}

.OrderBtns {
    display: block;
}

.POD h6 {
    height: 250px;
    overflow: scroll;
}

.compSelect {
    height: 250px;
    overflow: scroll;
    width: 1000px;
    margin-left: 200px;
}











.dropdownss {

    display: inline-block;
    border: 1px solid rgb(187, 186, 186);
    padding: 8px;
    width: 1100px;
    border-radius: 5px;
    margin-left: 200px;
}

.dropdown-contentt {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    transition: 1s;
}

.dropdownss:hover .dropdown-contentt {
    display: block;
    width: 1100px;
    margin-left: -9px;
    margin-top: 15px;
    border-top: 3px solid #66cccc;
    height: 250px;
    overflow: scroll;

}

.dropdown-contentt li {
    list-style: none;
    padding: 16px;
    margin-left: -20px;
    margin-top: -10px;
}

.dropdown-contentt li:hover {
    background-color: rgb(237, 236, 236);
    width: 555px;
    transition: 0.9s;
}

.dropdwnsName {
    color: rgb(117, 117, 117);
}

.PrdtName {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    font-weight: 600;

}

.InputNumber {
    width: 100px;
    margin-right: -50px;
    border-radius: 5px;
    border: 2px solid rgb(212, 211, 211);
}

.UserDiv {
    margin-top: 8px;
    margin-left: 200px;
}

.UserDiv p {
    font-size: 19px;
    margin-bottom: -2px;
    color: rgb(62, 62, 62);
    font-weight: 300;
}

.reportBTN {
    background-color: #eea236;
    width: 150px;
    border: 1px solid #eea236;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;



}

.backBTN {
    background-color: #337ab7;
    color: white;
    width: 150px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #337ab7;
}

.logoutBTN {
    margin-left: 20px;
    width: 150px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgb(235, 234, 234);
    background-color: rgb(235, 234, 234);
    color: rgb(152, 151, 151);
}